import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundImage: "url('assets/images/background.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  box: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    width: '90%',
    maxHeight: '80%', // Limit the overall box height
    overflow: 'hidden', // Prevent scrolling of the entire box
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'sticky', // Keeps it fixed at the top of the scrolling area
    top: 0,
    zIndex: 1, // Ensures it stays above the content
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Match the box background
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc', // Optional visual separator
  },
  content: {
    flex: 1, // Takes the remaining height of the box
    overflowY: 'auto', // Enables scrolling for this section
  },
  title: {
    fontWeight: 'bold',
    margin: '0 0 20px 0',
    fontSize: '22px',
    lineHeight: '28px',
    color: '#333',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 'bold',
    margin: '0 0 20px 0',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  paragraph: {
    fontSize: '1rem',
    color: '#555',
    textAlign: 'justify',
    lineHeight: '1.6',
    marginBottom: '16px',
  },
  list: {
    margin: '16px 0',
    paddingLeft: '20px',
    color: '#555',
    fontSize: '1rem',
  },
  listItem: {
    marginBottom: '8px',
  },
  logo: {
    maxWidth: '200px',
    margin: '0 auto 20px auto',
    display: 'block',
  },
};

class Privacy extends Component {
  render() {
    return (
      <AUX>
        <div style={styles.container}>
          <div style={styles.box}>
            <div style={styles.header}>
              <img
                style={styles.logo}
                src="assets/images/logo-full.png"
                alt="logo"
              />
              <h1 style={styles.title}>User Privacy</h1>

              {/* <hr/> */}
            </div>
            <div style={styles.content}>
  
            <p style={styles.paragraph}>
                This privacy policy governs your use of the software application TRUCKMEMO (“Application”)
                for mobile devices that was created by TRUCKMEMO.
              </p>
              <h2 style={styles.title}>What information does the Application obtain and how is it used?</h2>

              <h3 style={styles.title}>User Provided Information</h3>
              <p style={styles.paragraph}>
                The Application obtains the information you provide when you download and register the
                Application. Registration with us is mandatory.
              </p>
              <p style={styles.paragraph}>
                When you register with us and use the Application, you generally provide (a) your name,
                email address, password, phone number and other registration information; (b)
                transaction-related information, such as when you make purchases, respond to any offers, or
                download or use applications from us; (c) information you provide us when you contact us
                for help; (d) credit card information for purchase and use of the Application, and; (e)
                information you enter into our system when using the Application, such as car information
                or any personal information; (f) your location, information about your load, orders
                requests, opportunities, info about your business capabilities.
              </p>
              <p style={styles.paragraph}>
                We may also use the information you provided us to contact you from time to time to provide
                you with important information, required notices and marketing promotions.
              </p>

              <h3 style={styles.title}>Automatically Collected Information</h3>
              <p style={styles.paragraph}>
                In addition, the Application may collect certain information automatically, including, but
                not limited to, the type of mobile device you use, your mobile device’s unique device ID,
                the IP address of your mobile device, your mobile operating system, GPS location, the type
                of mobile Internet browsers you use, and information about the way you use the Application.
              </p>

              <h3 style={styles.title}>
                Does the Application collect precise real-time location information of the device?
              </h3>
              <p style={styles.paragraph}>
                This Application does collect precise information about the location of your mobile device
                unless this feature was explicitly turned off by the User.
              </p>

              <h3 style={styles.title}>
                Do third parties see and/or have access to information obtained by the Application?
              </h3>
              <p style={styles.paragraph}>
                Yes. For example, the information about the car damages may be seen by other users who
                bought this information.
              </p>
              <p style={styles.paragraph}>
                User personal information or the information about the car, results of the car inspections
                or any other entered or loaded by the User information can be shared partially or fully
                with the third parties without any notification or approvals from the Users. Also, the info
                about user location may be shown to third parties (this is required for the usual app
                business process).
              </p>
              <p style={styles.paragraph}>
                We may disclose User Provided and Automatically Collected Information:
              </p>
              <ul style={styles.list}>
                <li style={styles.listItem}>
                  as required by law, such as to comply with a subpoena, or similar legal process;
                </li>
                <li style={styles.listItem}>
                  when we believe in good faith that disclosure is necessary to protect our rights, protect
                  your safety or the safety of others, investigate fraud, or respond to a government
                  request;
                </li>
                <li style={styles.listItem}>
                  with our trusted services providers who work on our behalf, do not have an independent
                  use of the information we disclose to them, and have agreed to adhere to the rules set
                  forth in this privacy statement;
                </li>
                <li style={styles.listItem}>
                  if TRUCKMEMO is involved in a merger, acquisition, or sale of all or a portion
                  of its assets, you will be notified via email and/or a prominent notice on our Web site
                  of any change in ownership or uses of this information, as well as any choices you may
                  have regarding this information;
                </li>
                <li style={styles.listItem}>
                  to other third parties in the order of performing the company’s business processes.
                </li>
              </ul>

              <h3 style={styles.title}>What are my opt-out rights?</h3>
              <p style={styles.paragraph}>
                You can stop all collection of information by the Application easily by uninstalling the
                Application. You may use the standard uninstall processes as may be available as part of
                your mobile device or via the mobile application marketplace or network.
              </p>

              <h3 style={styles.title}>
                Data Retention Policy, Managing Your Information
              </h3>
              <p style={styles.paragraph}>
                We will retain User Provided data for as long as you use the Application and thereafter. We
                will retain Automatically Collected information until it’s reasonable for the company. If
                you’d like us to delete User Provided Data that you have provided via the Application,
                please contact us at admin@truckmemo.com and we will respond in a reasonable time. Please
                note that some or all of the User Provided Data may be required in order for the
                Application to function properly.
              </p>

              <h3 style={styles.title}>Children</h3>
              <p style={styles.paragraph}>
                We do not use the Application to knowingly solicit data from or market to children under
                the age of 13. If a parent or guardian becomes aware that his or her child has provided us
                with information without their consent, he or she should contact us at admin@truckmemo.com.
                We will respond in a reasonable time.
              </p>

              <h3 style={styles.title}>Security</h3>
              <p style={styles.paragraph}>
                We are concerned about safeguarding the confidentiality of your information. We provide
                physical, electronic, and procedural safeguards to protect information we process and
                maintain. For example, we limit access to this information to authorized employees and
                contractors who need to know that information in order to operate, develop or improve our
                Application. Please be aware that, although we endeavor to provide reasonable security for
                information we process and maintain, no security system can prevent all potential security
                breaches.
              </p>

              <h3 style={styles.title}>Changes</h3>
              <p style={styles.paragraph}>
                This Privacy Policy may be updated from time to time for any reason. We will notify you of
                any changes to our Privacy Policy by posting the new Privacy Policy here. You are advised
                to consult this Privacy Policy regularly for any changes, as continued use is deemed
                approval of all changes.
              </p>

              <h3 style={styles.title}>Your Consent</h3>
              <p style={styles.paragraph}>
                By using the Application, you are consenting to our processing of your information as set
                forth in this Privacy Policy now and as amended by us. “Processing,” means using cookies on
                a computer/handheld device or using or touching information in any way, including, but not
                limited to, collecting, storing, deleting, using, combining and disclosing information, all
                of which activities will take place in the United States. If you reside outside the United
                States your information will be transferred, processed and stored there under United States
                privacy standards.
              </p>

              <h3 style={styles.title}>Contact us</h3>
              <p style={styles.paragraph}>
                If you have any questions regarding privacy while using the Application, or have questions
                about our practices, please contact us via email at admin@truckmemo.com.
              </p>

            </div>
            <hr />
            &copy; 2024 TRUCKMEMO. All rights reserved.
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Privacy);
