import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundImage: "url('assets/images/background.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  box: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    width: '90%',
    maxHeight: '80%', // Limit the overall box height
    overflow: 'hidden', // Prevent scrolling of the entire box
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'sticky', // Keeps it fixed at the top of the scrolling area
    top: 0,
    zIndex: 1, // Ensures it stays above the content
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Match the box background
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc', // Optional visual separator
  },
  content: {
    flex: 1, // Takes the remaining height of the box
    overflowY: 'auto', // Enables scrolling for this section
  },
  title: {
    fontWeight: 'bold',
    margin: '0 0 20px 0',
    fontSize: '22px',
    lineHeight: '28px',
    color: '#333',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 'bold',
    margin: '0 0 20px 0',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  paragraph: {
    fontSize: '1rem',
    color: '#555',
    textAlign: 'justify',
    lineHeight: '1.6',
    marginBottom: '16px',
  },
  list: {
    margin: '16px 0',
    paddingLeft: '20px',
    color: '#555',
    fontSize: '1rem',
  },
  listItem: {
    marginBottom: '8px',
  },
  logo: {
    maxWidth: '200px',
    margin: '0 auto 20px auto',
    display: 'block',
  },
};

class Terms extends Component {
  render() {
    return (
      <AUX>
        <div style={styles.container}>
          <div style={styles.box}>
            <div style={styles.header}>
              <img
                style={styles.logo}
                src="assets/images/logo-full.png"
                alt="logo"
              />
              <h1 style={styles.title}>User Terms and Conditions</h1>
              {/* <hr/> */}
            </div>
            <div style={styles.content}>
    
            <p style={styles.paragraph}>
              Please carefully read all of the following terms and conditions of this user terms and
              conditions agreement document (“Agreement”) before clicking an “agree” or “sign up” or
              similar button or installing or using the mobile or Web application and/or the dispatch
              dashboard (“application”). This agreement is a legally binding contract between you and
              Truckmemo (“Vendor”) that sets forth the terms and conditions that govern your use
              of all of the services and software. By clicking an “agree” or “activate” or “sign up” or
              similar button or by installing and/or using the application, you agree to abide by all of
              the terms and conditions stated or referenced herein.
            </p>
            <p style={styles.paragraph}>
              If you do not agree to abide by these terms and conditions, do not click an “agree” or
              “activate” or “sign up” or similar button and do not install or use the application/s. Your
              use of the application, software or services constitutes your acceptance of terms and
              conditions of this agreement. You must accept and abide by these terms and conditions as
              presented to you – any changes, additions or deletions by you to these terms and conditions
              will not be accepted by the vendor and will not be part of this agreement.
            </p>

            <h2 style={styles.subtitle}>License</h2>
            <p style={styles.paragraph}>
              Under this End User License Agreement (the “Agreement”), TRUCKMEMO (the “Vendor”)
              grants to the user (the “Licensee”) a non-exclusive and non-transferable license (the
              “License”) to use TRUCKMEMO APP (the “Application”).
            </p>
            <p style={styles.paragraph}>
              “Application” includes the executable Web or/and cell phone/tablet programs and any related
              printed, electronic and online documentation and any other files that may accompany the
              product.
            </p>
            <p style={styles.paragraph}>
              Title, copyright, intellectual property rights and distribution rights of the Application
              remain exclusively with the Vendor. Intellectual property rights include the look and feel
              of the Application. This Agreement constitutes a license for use only and is not in any way
              a transfer of ownership rights to the Application.
            </p>
            <p style={styles.paragraph}>
              The Application may be loaded on the web browser (web app) or a mobile app onto a cell
              phone/tablet.
            </p>
            <p style={styles.paragraph}>
              The rights and obligations of this Agreement are personal rights granted to the Licensee
              only. The Licensee may not transfer or assign any of the rights or obligations granted under
              this Agreement to any other person or legal entity. The Licensee may not make available the
              Application for use by one or more third parties.
            </p>
            <p style={styles.paragraph}>
              The Application may not be modified, reverse-engineered, or de-compiled in any manner
              through current or future available technologies.
            </p>
            <p style={styles.paragraph}>
              Failure to comply with any of the terms under the License section will be considered a
              material breach of this Agreement.
            </p>

            <h2 style={styles.subtitle}>Limitation of Liability</h2>
            <p style={styles.paragraph}>
              The Application is provided by the Vendor and accepted by the Licensee “as is”. The liability
              of the Vendor will be limited to a maximum of the original purchase price of the
              Application. The Vendor will not be liable for any general, special, incidental or
              consequential damages including, but not limited to, loss of production, loss of profits,
              loss of revenue, loss of data, or any other business or economic disadvantage suffered by the
              Licensee arising out of the use or failure to use the Application.
            </p>
            <p style={styles.paragraph}>
              The Vendor makes no warranty expressed or implied regarding the fitness of the Application
              for a particular purpose or that the Application will be suitable or appropriate for the
              specific requirements of the Licensee.
            </p>
            <p style={styles.paragraph}>
              The Vendor does not warrant that use of the Application will be uninterrupted or error-free.
              The Licensee accepts that Application in general is prone to bugs and flaws within an
              acceptable level as determined in the industry.
            </p>

            <h2 style={styles.subtitle}>Users Responsibilities and Accepting Terms</h2>
            <p style={styles.paragraph}>
              By agreeing and signing these terms and conditions Sender (origin), Receiver (destination),
              Dispatcher, Broker, Dealer, Transporter or any other user acknowledge the following terms:
            </p>

            <h3 style={styles.subtitle}>Sender (Origin or Broker or Dealer)</h3>
            <p style={styles.paragraph}>
              Sender authorizes the transporter and its driver to operate and transport the assigned motor
              vehicle(s) between its pick-up location and the destination set forth on this shipping
              order-bill of lading. The sender may change destination by contacting the transporter. The
              sender acknowledges that the transporter might apply additional charges for changing
              destination.
            </p>
            <p style={styles.paragraph}>
              The sender is responsible for preparing the vehicle(s) for transport, all loose, fragile or
              protruding accessories, low hanging spoilers, fog lights, antennas, etc., must be removed
              and/or properly secured. Any part that falls off in transit is the shipper’s responsibility,
              including damages done by said part to any and all vehicles involved.
            </p>
            <p style={styles.paragraph}>
              Pick up is from the sender’s location unless residential area restrictions apply. In this
              case, the sender and transporter agree to the nearest pick-up location where the
              transporter’s truck can access. If the motor vehicle(s) is inoperable or oversize extra
              charges may be applied by the Transporter. The sender should provide the key of the motor
              vehicle(s) to the transporter and authorize transporter to drive the motor vehicle(s) for
              transportation purposes only.
            </p>
            <p style={styles.paragraph}>
              The sender allows the transporter to inspect the motor vehicle(s) and signing the inspection
              form agrees with the accuracy of the inspection.
            </p>
            <p style={styles.paragraph}>
              Sender agrees that luggage and personal property must be confined to the trunk, with no heavy
              articles, and not to exceed 100 lbs. The transporter is not liable for personal items left in
              the vehicle, nor for damage caused to vehicle from excessive or improper loading of personal
              items. Transporter shall not be liable for failure of mechanical or operating parts of the
              motor vehicle(s).
            </p>
            <p style={styles.paragraph}>
              Sender can ask the transporter to present the proof of insurance.
            </p>
            <p style={styles.paragraph}>
              No electronic equipment, valuables, plants, live pets, alcohol, drugs or firearms, may be
              left in the motor vehicle(s).
            </p>
            <p style={styles.paragraph}>
              The information about the car, results of the car inspections or any other entered or loaded
              by the user information can be shared partially or fully with the third parties without any
              notification or approvals from the Users, Receivers, or Senders.
            </p>
            <p style={styles.paragraph}>
              This agreement and any car transport hereunder are subject to all terms and conditions of
              the Transporter’s tariff and the uniform straight bill of lading, copies of which are
              available and must be provided by the Transporter.
            </p>

            <h2 style={styles.subtitle}>Dispatcher</h2>
            <p style={styles.paragraph}>
              Dispatcher authorizes the transporter company and its driver to operate and transport the
              assigned motor vehicle(s) between its pick-up location and the destination set forth on this
              shipping order-bill of lading. The Dispatcher may change destination by contacting the
              transporter. The dispatcher acknowledges that the transporter might apply additional charges
              for changing destination.
            </p>
            <p style={styles.paragraph}>
              The dispatcher is responsible for arranging all needed agreements between Sender and
              Receiver. The dispatcher should provide all the needed info to Transporter, for him to be
              able to successfully deliver vehicles.
            </p>
            <p style={styles.paragraph}>
              The dispatcher can ask the transporter to present the proof of insurance and DOT.
            </p>
            <p style={styles.paragraph}>
              This agreement and any car transport hereunder are subject to all terms and conditions of
              the Transporter’s tariff and the uniform straight bill of lading, copies of which are
              available and must be provided by the Transporter.
            </p>

            <h2 style={styles.subtitle}>Transporter</h2>
            <p style={styles.paragraph}>
              The transporter is acting in the best interest of the dispatcher, sender, and receiver but
              cannot guarantee pick-up or delivery on a specified date or time. However, the transporter
              should notify the dispatcher, sender, and receiver 24 hours before picking up/delivering the
              vehicle.
            </p>
            <p style={styles.paragraph}>
              The transporter is solely responsible for the condition of the motor vehicle(s) while it is
              in his/her possession. Sender and Receiver should check the vehicle at pick up and
              destination and if they do not agree with the condition of the vehicle they should notify
              the transporter of such condition. Sender and Receiver should document such conditions by
              taking pictures and marking the damage on the inspection form. Once inspection form is
              signed by sender and receiver they acknowledge the condition of the vehicle as shown on the
              inspection form. After signature Sender and receiver agree to pay the transportation fee.
              All claims must be noted and signed for at the time of delivery, and submitted in writing to
              the transporter within the terms of the transporter’s bill of lading.
            </p>

            <h2 style={styles.subtitle}>Receiver (Destination or Broker or Dealer)</h2>
            <p style={styles.paragraph}>
              The Receiver of the motor vehicle(s) may inspect the condition of the vehicle and submit a
              claim if conditions of the vehicle are different from the condition signed by the sender at
              pick up location. Damage must be noted in the proper place on the bill of lading and signed
              by the driver and receiver. Signing the bill of lading without any notation of damage
              verifies that the receiver has received a said vehicle in good condition, and that
              Transporter is relieved of any further responsibility.
            </p>
            <p style={styles.paragraph}>
              The delivery of the motor vehicle(s) should be at the receiver’s location unless residential
              area restrictions apply. In this case, the receiver and transporter agree to the nearest
              delivery location where the transporter’s truck can access. If the motor vehicle(s) is
              inoperable or oversize extra charges may be applied by the Transporter.
            </p>
            <p style={styles.paragraph}>
              Receiver shall not delay payment once signature on delivery is made and receiver agrees the
              inspection and conditions of the motor vehicle.
            </p>
            <p style={styles.paragraph}>
              This agreement and any car transport hereunder are subject to all terms and conditions of
              the Transporter’s tariff and the uniform straight bill of lading, copies of which are
              available and must be provided by the Transporter.
            </p>
            <p style={styles.paragraph}>
              The information about the car, results of the car inspections or any other entered or loaded
              by the user information can be shared partially or fully with the third parties without any
              notification or approvals from the Users, Receivers, or Senders.
            </p>
            <p style={styles.paragraph}>
              Any claim or controversy arising from or relating to this agreement, or the performance or
              breach thereof, shall be subject to the jurisdiction of the state where Transporter’s entity
              is incorporated.
            </p>

            <h2 style={styles.subtitle}>Liability Disclaimer</h2>
            <p style={styles.paragraph}>
              THESE EXCLUSION ARE ALL STANDARD BUSINESS PRACTICES OF THE AUTO TRANSPORTATION INDUSTRY:
            </p>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                Damage to the undercarriage, exhaust system, suspension, wheel bearings, tie downs, brakes,
                alignment, tuning, charging system or battery. (no evaluation is made of these components
                or systems at pickup location) therefore Transporter or its drivers do not accept
                responsibility for them.
              </li>
              <li style={styles.listItem}>
                Damage not detected at pickup location due to poor weather or lighting conditions.
              </li>
              <li style={styles.listItem}>
                Damage to car phones or antenna under any condition. (AAT suggests that they are removed)
              </li>
              <li style={styles.listItem}>
                Loss of or damage to audio or video equipment not installed at the factory. Including
                antenna that does not retract to within 3 inches of the vehicle’s body.
              </li>
              <li style={styles.listItem}>
                Damage or fines incurred because shippers left personal or household items in the vehicle.
              </li>
              <li style={styles.listItem}>
                Damage caused by fluids or objects flying up from the roadway, or out of the sky.
              </li>
              <li style={styles.listItem}>
                Damage caused by vandalism during transport.
              </li>
              <li style={styles.listItem}>
                Damage caused by leaking fluids, such as battery acid, motor oil, transmission fluid, brake
                fluid, power steering fluid, radiator coolant, or fallout resulting from acts of good.
              </li>
              <li style={styles.listItem}>
                Damage to, or caused by any vehicle that cannot be driven on or off the transport under its
                own power. (vehicle will not run, or has lost its braking system)
              </li>
            </ul>

            <h2 style={styles.subtitle}>Warrants and Representations</h2>
            <p style={styles.paragraph}>
              The Vendor warrants and represents that it is the copyright holder of the Application. The
              Vendor warrants and represents that granting the license to use this Web or Mobile
              Application is not in violation of any other agreement, copyright or applicable statute.
            </p>

            <h2 style={styles.subtitle}>Acceptance</h2>
            <p style={styles.paragraph}>
              All terms, conditions, and obligations of this Agreement will be deemed to be accepted by
              the Licensee (“Acceptance”) after installing/using the app or accepting the terms of the
              application.
            </p>
            <p style={styles.paragraph}>
              User accept to use this application AS IS.
            </p>
            <p style={styles.paragraph}>
              TRUCKMEMO does not guarantee any application or servers stability.
            </p>
            <p style={styles.paragraph}>
              TRUCKMEMO does not guarantee the authenticity of the information used in the
              application.
            </p>

            <h2 style={styles.subtitle}>User Support</h2>
            <p style={styles.paragraph}>
              The Licensee will be entitled to lifetime of e-mail support available 10:00 AM to 5:00 PM
              Eastern, at no additional cost.
            </p>
            <p style={styles.paragraph}>
              The Licensee will be entitled to maintenance upgrades and bug fixes, at no additional cost.
            </p>
            <p style={styles.paragraph}>
              The SLA conditions could be changed by TRUCKMEMO without any notifications at any time.
            </p>

            <h2 style={styles.subtitle}>Term</h2>
            <p style={styles.paragraph}>
              The term of this Agreement will begin on Acceptance and registration on TRUCKMEMO APP and is
              perpetual.
            </p>

            <h2 style={styles.subtitle}>Termination</h2>
            <p style={styles.paragraph}>
              This Agreement will be terminated and the License forfeited where the Licensee has failed to
              comply with any of the terms of this Agreement or is in breach of this Agreement. On
              termination of this Agreement for any reason, the Licensee will promptly destroy the
              Application or return the Application to the Vendor.
            </p>

            <h2 style={styles.subtitle}>Force Majeure</h2>
            <p style={styles.paragraph}>
              The Vendor will be free of liability to the Licensee where the Vendor is prevented from
              executing its obligations under this Agreement in whole or in part due to Force Majeure,
              such as earthquake, typhoon, flood, fire, and war or any other unforeseen and uncontrollable
              event where the Vendor has taken any and all appropriate action to mitigate such an event.
            </p>

            <h2 style={styles.subtitle}>Additional Clauses</h2>
            <p style={styles.paragraph}>
              TRUCKMEMO, the owner and operator of TRUCKMEMO APP, cannot be held responsible for
              the accuracy of information provided in the app, location of the transporters, dealers, or
              inspection of the motor vehicle(s). Any dispute regarding the motor vehicle(s) being
              transported by the transporter in no case should be addressed to TRUCKMEMO
            </p>
            <p style={styles.paragraph}>
              User personal information, the information about user locations, provided services, any
              personal information, information about orders and opportunities, or the information about
              the car, results of car inspections, or any other entered or loaded user information can be
              shared partially or fully with third parties without any notification or approvals from the
              Users according to the business and operational models of Truckmemo.
            </p>
            <p style={styles.paragraph}>
              Truckmemo app uses information that has a confidential nature (contains personal data) entered
              by other users. By accepting these terms and conditions, all users should treat the
              information in Truckmemo app as confidential with a high level of security, according to their
              best knowledge. This information shall not be used for any law-breaking activities, which
              can harm people, private property, or any other activities that may cause any physical or
              material damage to other companies or individuals.
            </p>
            <p style={styles.paragraph}>
              The information circulating in Truckmemo app cannot be sold by third parties (including but
              not limited to: Brokers, Dealers, Dispatchers, Transporters, Car Buyers) to anyone else
              (outside of the use of Truckmemo App) without written approval from a legal representative of
              Truckmemo Inc.
            </p>

            <h2 style={styles.subtitle}>Governing Law</h2>
            <p style={styles.paragraph}>
              The Parties to this Agreement submit to the jurisdiction of the courts of the State of
              Delaware for the enforcement of this Agreement or any arbitration award or decision arising
              from this Agreement. This Agreement will be enforced or construed according to the laws of
              the State of Delaware.
            </p>

            <h2 style={styles.subtitle}>Payments Inside the Application: Deposit and Withdraw</h2>
            <p style={styles.paragraph}>
              Due to internal operations and transaction processes with third parties (like Apple In-App
              Purchase, PayPal, etc.), the time of money transaction could take up to 6 business days or
              more.
            </p>
            <p style={styles.paragraph}>
              Exchange rates for buying/selling Application Points, for different user types (Car Buyers,
              Transporters), could vary. The exchange difference covers transaction fees and service fees
              for third-party companies (like Apple, PayPal, etc.).
            </p>
            <p style={styles.paragraph}>
              Car Buyers (a type of user in the application) cannot withdraw money or exchange internal
              application points for real money. TRUCKMEMO is not responsible (and will not
              provide) for any money returns or exchange of internal application’s money to real money
              (like USD).
            </p>
            <p style={styles.paragraph}>
              There is a withdrawal limitation for Transporters (a type of user in the application).
              Limits can be changed without notification of the users. The limit size may vary.
            </p>
            <p style={styles.paragraph}>
              The exchange rates (USD/Points – Points/USD) can be changed without notification.
            </p>
            <p style={styles.paragraph}>
              The amount of reward/compensation for Users could be changed by TRUCKMEMO without
              notification or any confirmation from the Users.
            </p>
            <p style={styles.paragraph}>
              All purchases are final. The vendor does not support any returns or reimbursements. In case
              the user cancels the license before the license period is over, the license will remain
              active until the end of the billing period.
            </p>
            <p style={styles.paragraph}>
              The vendor reserves the right to change prices and fees without notification.
            </p>

            <h2 style={styles.subtitle}>Miscellaneous</h2>
            <p style={styles.paragraph}>
              This Agreement does not create or imply any relationship in agency or partnership between
              the Vendor and the Licensee.
            </p>
            <p style={styles.paragraph}>
              Headings are inserted for the convenience of the parties only and are not to be considered
              when interpreting this Agreement. Words in the singular mean and include the plural and vice
              versa. Words in the masculine gender include the feminine gender and vice versa. Words in
              the neuter gender include the masculine gender and the feminine gender and vice versa.
            </p>
            <p style={styles.paragraph}>
              If any term, covenant, condition, or provision of this Agreement is held by a court of
              competent jurisdiction to be invalid, void, or unenforceable, it is the parties’ intent that
              such provision be reduced in scope by the court only to the extent deemed necessary by that
              court to render the provision reasonable and enforceable. The remainder of the provisions of
              this Agreement will in no way be affected, impaired, or invalidated as a result.
            </p>
            <p style={styles.paragraph}>
              This Agreement contains the entire agreement between the parties. All understandings have
              been included in this Agreement. Representations that may have been made by any party to
              this Agreement may in some way be inconsistent with this final written Agreement. All such
              statements are declared to be of no value in this Agreement. Only the written terms of this
              Agreement will bind the parties.
            </p>
            <p style={styles.paragraph}>
              This Agreement and the terms and conditions contained in this Agreement apply to and are
              binding upon the Vendor’s successors and assigns.
            </p>

            <h2 style={styles.subtitle}>Notices</h2>
            <p style={styles.paragraph}>
              All notices to the Vendor under this Agreement are to be provided at the following address:
            </p>
            <p style={styles.paragraph}>
              TRUCKMEMO: 16192 Coastal Highway Lewes, DE 19958 and the copy should be sent to
              the email: admin@truckmemo.com.
            </p>
            <p style={styles.paragraph}>
              Parties (Transporter, Dispatchers, Car Buyers, Broker, Dealer, Sender (Origin), and Receiver
              (Destination)) agree that the Vendor cannot be held responsible for any damage to the motor
              vehicle(s) being transported or any business damages (including but not limited to
              reputational, financial, etc.), or any payment dispute. The vendor is providing only a tool
              or marketplace for opportunities, transporter’s location, and availabilities as well as the
              inspection tool (but the transporter is responsible for the accuracy of such inspection and
              all information including pictures, videos, damage listings logged in TRUCKMEMO app by the
              Transporter). Any dispute between Sender (Origin), Transporter, Broker, Dealer, Dispatcher,
              and Receiver (Destination) should not be addressed to the vendor under any circumstances.
            </p>

            <p style={styles.paragraph}>
              By agreeing and signing these terms and conditions, Broker/Dispatcher/Dealer/Transporter/Inspector/Car
              Buyer or any other user acknowledges additionally (to the terms above) the following terms:
            </p>
            <ul style={styles.list}>
              <li style={styles.listItem}>The transporter is acting in the best interest of the sender and receiver.</li>
              <li style={styles.listItem}>Transporter is solely responsible for the condition of the motor vehicle(s) while it is in his/her possession.</li>
              <li style={styles.listItem}>
                The information entered by Transporter, Broker, Dispatcher, Dealer, Car Buyer, Inspector,
                or any other party in the TRUCKMEMO app (mobile or web) could be saved, maintained, and used
                by TRUCKMEMO sys. at any time, even after the user deleted their account. In case the user
                wants their data to be deleted, they should contact Truckmemo support department –
                admin@truckmemo.com, and these data may be deleted by the request.
              </li>
              <li style={styles.listItem}>The Dispatchers, Brokers, and Dealers are acting in the best interest of Transporters and their clients.</li>
            </ul>

            <h1 style={styles.subtitle}>Used Terms</h1>

            <p style={styles.paragraph}>
              <strong>Sender (Origin):</strong> An individual or a business, dealership, auction sending the
              motor vehicle(s).
            </p>
            <p style={styles.paragraph}>
              <strong>Dispatcher:</strong> An individual or a company, which are using car transporters to
              deliver the motor vehicle(s) for their clients.
            </p>
            <p style={styles.paragraph}>
              <strong>Receiver (Destination):</strong> An individual or a business, dealership, auction, or
              other receiving the motor vehicle(s).
            </p>
            <p style={styles.paragraph}>
              <strong>Transporter/Inspector:</strong> The auto Transporter or its driver, transporting the
              motor vehicle(s) between set pick-up and destinations.
            </p>
            <p style={styles.paragraph}>
              <strong>Car Buyer:</strong> The person or the company who purchase the information in the
              application.
            </p>
            <p style={styles.paragraph}>
              <strong>Broker:</strong> The person or the company who can create orders to deliver the
              vehicle(s). The same person/company can be Sender (Origin) or Receiver (Destination).
            </p>
            <p style={styles.paragraph}>
              <strong>Dealer:</strong> The person or the company who can create orders to deliver the
              vehicle(s). The same person/company can be Sender (Origin) or Receiver (Destination).
            </p>
            <p style={styles.paragraph}>
              <strong>TRUCKMEMO:</strong> A web and phone application and a website that provides services
              through its app.
            </p>
            <p style={styles.paragraph}>
              <strong>Data Protection Law:</strong> Means all laws and regulations applicable to the
              personal data under the Agreement, including as applicable the laws and regulations of the
              European Union, the European Economic Area and their member states, Switzerland and the
              United Kingdom, including the EU General Data Protection Regulation (2016/679) (GDPR). “User
              Terms” shall mean the terms and conditions applicable to all users of the Truckmemo,
              available at the corporate website as may be updated by Truckmemo from time to time.
            </p>
            <p style={styles.paragraph}>
              <strong>Personal Data:</strong> Means any information in connection with this Agreement that
              can reasonably be used to identify an individual, or that may otherwise be considered
              personal data.
            </p>

            <h1 style={styles.subtitle}>Data Processing Addendum</h1>

            <p style={styles.paragraph}>
              This GDPR Data Processing Addendum (“DPA”) forms part of the User terms and conditions or
              Terms of Use available at Vendor’s website or such other location as the Terms of Use may be
              posted from time to time (as applicable, the “Agreement”), entered into by and between the
              Users and Truckmemo (“Truckmemo”), pursuant to which Users have accessed Truckmemo
              Application Services as defined in the applicable Agreement. The purpose of this
              DPA is to reflect the parties’ agreement with regard to the processing of personal data in
              accordance with the requirements of Data Protection Legislation as defined below.
            </p>
            <p style={styles.paragraph}>
              If the Users entity entering into this DPA has executed an order form or statement of work
              with Truckmemo pursuant to the Agreement (an “Ordering Document”) but is not itself a
              party to the Agreement, this DPA is an addendum to that Ordering Document and applicable
              renewal Ordering Documents. If the Users entity entering into this DPA is neither a party to
              an Ordering Document nor the Agreement, this DPA is not valid and is not legally binding.
              Such entity should request that the Users entity that is a party to the Agreement executes
              this DPA.
            </p>
            <p style={styles.paragraph}>
              This DPA shall not replace or supersede any agreement or addendum relating to processing of
              personal data negotiated by Users and referenced in the Agreement, and any such
              individually negotiated agreement or addendum shall apply instead of this DPA.
            </p>
            <p style={styles.paragraph}>
              In the course of providing the Application Services to Users pursuant to the Agreement,
              Truckmemo may process personal data on behalf of Users. Truckmemo agrees to
              comply with the following provisions with respect to any personal data submitted by or for
              Users to the Application Services or collected and processed by or for Users through the
              Application Services. Any capitalized but undefined terms herein shall have the meaning set
              forth in the Agreement.
            </p>

            <h2 style={styles.subtitle}>Data Processing Terms</h2>

            <p style={styles.paragraph}>
              In this DPA, “Data Protection Legislation” means European Directives 95/46/EC and 2002/58/EC
              (as amended by Directive 2009/136/EC) and any legislation and/or regulation implementing or
              made pursuant to them, or which amends, replaces, re-enacts or consolidates any of them
              (including the General Data Protection Regulation (Regulation (EU) 2016/279)), and all other
              applicable laws relating to processing of personal data and privacy that may exist in any
              relevant jurisdiction.
            </p>
            <p style={styles.paragraph}>
              “data controller”, “data processor”, “data subject”, “personal data”, “processing”, and
              “appropriate technical and organizational measures” shall be interpreted in accordance with
              applicable Data Protection Legislation;
            </p>
            <p style={styles.paragraph}>
              The parties agree that Users and Truckmemo are the data controller and that Truckmemo
              Systems is its data processor in relation to personal data that is processed in the course
              of providing the Application Services.
            </p>
            <p style={styles.paragraph}>
              The subject-matter of the data processing covered by this DPA is the Application Services
              ordered by Users, or as additionally described in the Agreement or the DPA. The processing
              will be carried out until the term of Users’s ordering of the Application Services ceases
              and after this until User requested otherwise. Further details of the data processing are
              set out in Annex 1 hereto.
            </p>

            <h2 style={styles.subtitle}>In respect of personal data processed in the course of providing the Application Services, Truckmemo:</h2>

            <ul style={styles.list}>
              <li style={styles.listItem}>
                Shall process the personal data in accordance to the documented instructions (user manual,
                corporate website published instructions and documentation, Truckmemo App instruction, etc.)
                created by Truckmemo or requested by Users (as set out in this DPA or the Agreement
                or as otherwise notified by Users to Truckmemo from time to time). If Truckmemo
                Systems is required to process the personal data for any other purpose provided by
                applicable law to which it is subject, Truckmemo may have such rights. In case of
                Users disagreements, User should inform Truckmemo Support team about this.
              </li>
              <li style={styles.listItem}>
                Shall notify Users if, in Truckmemo opinion, an instruction for the processing of
                personal data given by Users infringes applicable Data Protection Legislation.
              </li>
              <li style={styles.listItem}>
                Shall implement and maintain technical and organizational measures designed to protect the
                personal data against unauthorized or unlawful processing, destruction, damage, theft,
                alteration or disclosure.
              </li>
              <li style={styles.listItem}>
                May hire other companies to provide limited services on its behalf, provided that Truckmemo
                Systems complies with the provisions of this Clause. If Users require prior notification
                of any updates to the list of subprocessors, Users can request such notification in
                writing by emailing admin@truckmemo.com. Legitimate objections must contain reasonable and
                documented grounds relating to a subcontractor’s non-compliance with applicable Data
                Protection Legislation. If in Truckmemo Systems’ reasonable opinion, such objections are
                legitimate, the Users may, by providing written notice to Truckmemo, terminate the
                Agreement.
              </li>
            </ul>

            <h2 style={styles.subtitle}>Annex 1</h2>

            <h3 style={styles.subtitle}>Details of the Data Processing</h3>

            <p style={styles.paragraph}>
              Truckmemo shall process information to provide the Application Services pursuant to
              the Agreement. Truckmemo shall process information sent by Users identified through
              Users’ implementation of the Application Services. As an example, in a standard
              programmatic implementation, to utilize the Application Services, Users may allow the
              following information to be sent by default as “default properties”:
            </p>

            <ul style={styles.list}>
              <li style={styles.listItem}>Country</li>
              <li style={styles.listItem}>Device Type</li>
              <li style={styles.listItem}>Operating System</li>
              <li style={styles.listItem}>Truckmemo Purchases SDK Version</li>
              <li style={styles.listItem}>GPS location</li>
              <li style={styles.listItem}>App User ID if set by Users</li>
              <li style={styles.listItem}>Name, phone number, address</li>
              <li style={styles.listItem}>Car inspection information with all related information to complete inspection form</li>
              <li style={styles.listItem}>Info about transporters, brokers, dealers location, dispatchers, parameters of their business capabilities, ratings</li>
              <li style={styles.listItem}>Info about transportation opportunities and orders which might include personal information</li>
              <li style={styles.listItem}>Any attribution data attached to the User or by Users</li>
            </ul>

            <h3 style={styles.subtitle}>Categories of Data Subjects</h3>

            <p style={styles.paragraph}>
              Users of the web and mobile applications or a website.
            </p>

            <h3 style={styles.subtitle}>Processing Activities</h3>

            <p style={styles.paragraph}>
              The provision of Application Services by Truckmemo to Users.
            </p>

            </div>
            <hr />
            &copy; 2024 Goforit. All rights reserved.
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Terms);
